<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">动物多样性监测</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="拍摄时间" prop="PSSJ">
                    <el-input
                        v-model="formdata.dataDic.PSSJ"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>

                <el-form-item label="中文名" prop="MC_ZW">
                    <el-input
                        v-model="formdata.dataDic.MC_ZW"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>

                <el-form-item label="拉丁名" prop="MC_LD">
                    <el-input
                        v-model="formdata.dataDic.MC_LD"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="物种类型" prop="WZLX">
                    <el-input
                        v-model="formdata.dataDic.WZLX"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="物种分布（相机布设点位）" prop="SBMC">
                    <el-input
                        v-model="formdata.dataDic.SBMC"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="种群密度" prop="ZQMD">
                    <el-input
                        v-model="formdata.dataDic.ZQMD"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="动物行为" prop="DWXW">
                    <el-input
                        v-model="formdata.dataDic.DWXW"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="俗名" prop="MC_S">
                    <el-input
                        v-model="formdata.dataDic.MC_S"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="动物数量" prop="DWSL">
                    <el-input
                        v-model="formdata.dataDic.DWSL"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="生境（植被、海拔）" prop="SJ">
                    <el-input
                        v-model="formdata.dataDic.SJ"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../../0_com_js/info_mixin.js";
export default {
    name: "",
    mixins: [infoMixin],
    data() {
        return {
            title: "",
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "8020515",
                dataDic: {
                    PSSJ: "",
                    MC_ZW: "",
                    MC_LD: "",
                    WZLX: "",
                    SBMC: "",
                    ZQMD: "",
                    DWXW: "",
                    MC_S: "",
                    DWSL: "",
                    SJ: "",
                },
            },
            SGLXList: [],
        };
    },
    mounted() {
        // this.GetEnum();
    },
    methods: {
        ...mapActions(["getAllEnumInfobytableID"]),
        async GetEnum() {
            // let res = await this.getAllEnumInfobytableID({
            //     bid: "1303",
            // });
            // let enumList = res || [];
            // enumList.forEach((v) => {
            //     if (v.COLUMNID === "JB") {
            //         this.SGLXList = v.dataItems;
            //     }
            // });
        },
    },
};
</script>

<style>
.yearBox .yearItem {
    width: 48% !important;
}
.yearBox .separate {
    padding: 0 10px;
}
</style>
